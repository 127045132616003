/* eslint-disable no-undef, react/prop-types, react/no-danger */
import React, { useState } from 'react'
import { graphql } from 'gatsby'
import {
  Flex,
  Page,
  H1,
  Hr,
  // Button,
  // H4,
  Paper,
  Text,
  FeatherIcon,
  Box,
  Slider,
  Badge,
} from '../../elements'
import { BoardgamePreview, SEO, Badges, FiltersButtons } from '../../components'
import { useFilters } from '../../utils'

const canFilter = true

const Boardgames = ({
  data: {
    boardgames: { nodes: boardgames },
    filters: { nodes: filtersArray },
  },
}) => {
  // const [filtersOpen, setFiltersOpen] = useState(false)
  const {
    filterValues: { onlyFavorites, ...filterValues },
    setFilterValue,
    resetFilters,
    setFilterList,
    filters,
    filtersOpen,
    setFiltersOpen,
    filtersSelected,
  } = useFilters(
    'boardgames',
    {
      audience: null,
      accessibility: null,
      duration: 120,
      maxPlayers: 1,
      onlyFavorites: false,
    },
    filtersArray,
  )
  const setFilterAccessibility = ({ ...params }) => {
    setFilterList({ property: 'accessibility', ...params })
  }
  const setFilterAudience = ({ ...params }) => {
    setFilterList({ property: 'audience', ...params })
  }

  const color = 'boardgames'

  return (
    <Page>
      {({ t }) => (
        <React.Fragment>
          <SEO
            {...{
              url: `/boardgames/`,
              title: t('boardgames'),
              // description: true,
            }}
          />
          <Paper
            {...{
              bg: 'boardgames',
            }}
          >
            <Flex.container
              {...{
                alignItems: 'center',
                py: 0,
                css: {
                  cursor: canFilter ? 'pointer' : 'auto',
                },
                ...(canFilter && {
                  onClick: () => {
                    setFiltersOpen(!filtersOpen)
                  },
                }),
              }}
            >
              <H1
                {...{
                  color: 'textAlt',
                  // px: [2, 2, 3],
                  // pl: 2,
                  css: {
                    flexGrow: 1,
                  },
                }}
              >
                {t('boardgames')}
              </H1>

              {canFilter && filtersSelected.length > 0 ? (
                <Badge
                  {...{
                    borderRadius: 3,
                    mx: 2,
                    my: '1px',
                    p: null,
                    px: 2,
                    py: '2px',
                    // mr: 1,
                    color,
                    bg: 'textAlt',
                    fontSize: 1,
                  }}
                >
                  {filtersSelected.length > 1
                    ? filtersSelected.length
                    : t(filtersSelected[0]) || '1'}
                </Badge>
              ) : (
                canFilter &&
                !onlyFavorites && (
                  <Text {...{ color: 'textAlt', m: null, mx: 2 }}>
                    {t('filters')}
                  </Text>
                )
              )}
              {canFilter && onlyFavorites && (
                <Flex
                  {...{
                    width: 3,
                    flexShrink: 0,
                    justifyContent: 'center',
                    alignItems: 'center',
                    bg: 'textAlt',
                    border: 2,
                    borderColor: 'textAlt',
                    mr: 2,
                    css: {
                      cursor: 'pointer',
                      borderRadius: '100%',
                    },
                  }}
                >
                  <FeatherIcon
                    {...{
                      icon: 'star',
                      stroke: color,
                      fill: 'textAlt',
                      // fill: color,
                      // size: 3,
                      size: 2,
                      // mr: 1,
                      // mb: 1,
                      css: {
                        // marginLeft: 'auto',
                        // marginRight: 'auto',
                        padding: 1,
                      },
                    }}
                  />
                </Flex>
              )}
              {canFilter && (
                <FeatherIcon
                  {...{
                    icon: filtersOpen ? 'arrow-up' : 'arrow-down',
                    color: 'textAlt',
                    size: 4,
                  }}
                />
              )}
            </Flex.container>
            <Box.container
              {...{
                color: 'textAlt',
                py: 0,
                maxHeight: filtersOpen ? '80vh' : '0px',
                css: {
                  transition: 'max-height .7s ease-out',
                  overflow: 'hidden',
                },
              }}
            >
              <Hr {...{ border: 0, borderBottom: 1, borderColor: 'textAlt' }} />

              <Badges
                {...{
                  mb: 2,
                  list: filters.accessibility
                    .filter(x => x && x !== 'differentLanguage')
                    .map(x => {
                      const isSelected =
                        filterValues.accessibility &&
                        filterValues.accessibility[x]

                      return {
                        val: x,
                        color: 'textAlt',
                        bg: isSelected ? 'textAlt' : color,
                        strokeWidth: isSelected ? '0px' : '2px',
                        isSelected,
                      }
                    }),
                  child: ({ val, color: c, bg, strokeWidth, isSelected }) => {
                    return (
                      <Box
                        {...{
                          display: 'inline-block',
                          onClick: () => {
                            setFilterAccessibility({ val, isSelected })
                          },
                        }}
                      >
                        <FeatherIcon
                          {...{
                            key: val,
                            icon: `bgf_${val}`,
                            color: c,
                            fill: bg,
                            strokeWidth,
                            size: 8,
                            mr: 2,
                          }}
                        />
                      </Box>
                    )
                  },
                }}
              />
              <Badges
                {...{
                  mb: 2,
                  list: filters.audience.map(x => {
                    const isSelected =
                      filterValues.audience && filterValues.audience[x]

                    return {
                      val: t(x),
                      color: isSelected ? color : 'altText',
                      bg: isSelected ? 'textAlt' : 'inherit',
                      toggleSelect: () => {
                        setFilterAudience({ val: x, isSelected })
                      },
                    }
                  }),
                }}
              />
              <Flex {...{ maxWidth: 360, mb: 2 }}>
                <Slider
                  {...{
                    // my: 3,
                    // mb: 4,
                    min: 10,
                    max: filters.duration.max,
                    step: 10,
                    bg: 'textAlt',
                    maxWidth: 240,
                    value: filterValues.duration,
                    onChange: e => {
                      setFilterValue({
                        property: 'duration',
                        val: e.target.value,
                      })
                    },
                  }}
                />
                <Text
                  {...{
                    color: 'textAlt',
                    m: null,
                    ml: 2,
                    textAlign: 'right',
                    css: {
                      flexShrink: 0,
                      flexGrow: 1,
                      // minWidth: 67,
                    },
                  }}
                >
                  {`${filterValues.duration} min`}
                </Text>
              </Flex>
              <Flex {...{ maxWidth: 360, mb: 2 }}>
                <Slider
                  {...{
                    min: 1,
                    max: 7,
                    step: 1,
                    bg: 'textAlt',
                    maxWidth: 240,
                    value: filterValues.maxPlayers,
                    onChange: e => {
                      setFilterValue({
                        property: 'maxPlayers',
                        val: e.target.value,
                      })
                    },
                  }}
                />
                <Text
                  {...{
                    color: 'textAlt',
                    m: null,
                    ml: 2,
                    textAlign: 'right',
                    css: {
                      flexShrink: 0,
                      flexGrow: 1,
                      // minWidth: 67,
                    },
                  }}
                >
                  {`${
                    filterValues.maxPlayers === '7'
                      ? '6+'
                      : filterValues.maxPlayers
                  } ${t('playerS')}`}
                </Text>
              </Flex>
              <FiltersButtons
                {...{
                  setFilterValue,
                  setFiltersOpen,
                  resetFilters,
                  color,
                  onlyFavorites,
                }}
              />
            </Box.container>
          </Paper>
          <Flex.container
            {...{
              flexWrap: 'wrap',
              // justifyContent: 'space-between',
              px: 0,
            }}
          >
            {boardgames.map((x, i) => {
              if (!x) return null

              return (
                <BoardgamePreview
                  {...{
                    key: x.id,
                    boardgame: x,
                    filterValues,
                    onlyFavorites,
                  }}
                />
              )
            })}
          </Flex.container>
        </React.Fragment>
      )}
    </Page>
  )
}

// ($category: String!, $queryName: String!)
export const pageQuery = graphql`
  query Boardgames($localizedFieldName: BoardgameFieldsEnum) {
    boardgames: allBoardgame(
      filter: { status: { eq: "published" }, proto: { eq: false } }
      sort: { fields: [$localizedFieldName] }
    ) {
      nodes {
        id
        nameCanonical
        nameFallback {
          en
          fr
          nl
        }
        proto
        duration
        featuredImage {
          url
          type
          thumbnails {
            small {
              height
              width
              url
            }
            large {
              height
              width
              url
            }
          }
        }
        accessibility
        age
        audience
        maxPlayers
        minPlayers
        relativePath
      }
    }

    filters: allFilter(filter: { onType: { eq: "boardgame" } }) {
      nodes {
        name
        values
        minValue
        maxValue
      }
    }
  }
`

export default Boardgames
